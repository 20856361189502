import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  UserIsAuthenticated,
  UserIsCorrector,
  UserIsCorrectorAdmin
} from 'utils/Auth';
import qs from 'query-string';

// Core pages
import SimpleSignup from 'sites/app/pages/SimpleSignup';
import SimpleLogin from 'sites/app/pages/SimpleLogin';
import Reset from 'sites/app/pages/Reset';
import Verify from 'sites/app/pages/Verify';
import RequestReset from 'sites/app/pages/RequestReset';
import Tracker from 'utils/Tracker';
import WhiteLabeler from 'utils/WhiteLabeler';
import DashboardV2 from 'sites/app/containers/DashboardV2';

// import NotFound from 'sites/app/pages/404'
// utils
import Loading from 'components/common/Loading';
import { isNotCompatible } from 'components/common/BrowserSupportModal';

const DashboardV3 = lazy(() => import('sites/app/pages/DashboardV3'));

// LAZY LOADING for smaller buidl footprint in order of importance
// "Code Splitting": https://reactjs.org/docs/code-splitting.html#reactlazy
const Settings = lazy(() => import('sites/app/pages/Settings'));
const Plans = lazy(() => import('sites/app/pages/Plans'));
const PlansV3 = lazy(() => import('sites/app/pages/PlansV3'));
const Payment = lazy(() => import('sites/app/pages/Payment'));
const Corrector = lazy(() => import('sites/app/containers/Corrector'));

const CorrectorAdmin = lazy(() => import('sites/app/pages/CorrectorAdmin'));

const EditDoc = lazy(() => import('sites/app/pages/EditDoc'));
const EditTranscript = lazy(() => import('sites/app/pages/EditTranscript'));
const VideoPopout = lazy(() => import('sites/app/pages/VideoPopout'));

// functional pages
const Logout = lazy(() => import('sites/app/pages/Logout'));
const NotFound = lazy(() => import('sites/app/pages/404'));
const Unauthorized = lazy(() => import('sites/app/pages/401'));
const Forbidden = lazy(() => import('sites/app/pages/403'));
const Unsupported = lazy(() => import('sites/app/pages/Unsupported'));
const NotAllowed = lazy(() => import('sites/app/pages/401'));
// Support pages
const Diagnostics = lazy(() => import('sites/app/containers/Diagnostics'));
const Maintenance = lazy(() => import('sites/app/pages/Maintenance'));
const ForcedErrorPage = lazy(() => import('sites/app/pages/ForcedErrorPage'));
const Sandbox = lazy(() => import('sites/app/pages/Sandbox'));

const MemberDirect = (page) => {
  return WhiteLabeler(Tracker(page));
};

const AccountManagement = (props) => {
  const { mode } = qs.parse(props.location.search);
  console.log(mode);
  if (mode === 'verifyEmailReminder') return <Verify reminder {...props} />;
  if (mode === 'verifyEmail') return <Verify {...props} />;
  else if (mode === 'resetPassword') return <Reset {...props} />;
  return <NotFound {...props} />;
};

const IS_MAINTENANCE = false; // window.localStorage.getItem('dowhat') !== 'doit'

export default () => {
  if (IS_MAINTENANCE) {
    return (
      <Switch>
        <Route component={Maintenance} />
      </Switch>
    );
  } else if (isNotCompatible()) {
    return <Route component={Unsupported} />;
  } else {
    return (
      <Suspense fallback={<Loading dark />}>
        <Switch>
          <Route exact path="/" component={MemberDirect(SimpleSignup)} />
          <Route
            exact
            path="/register"
            component={MemberDirect(SimpleSignup)}
          />
          <Route exact path="/signin" component={MemberDirect(SimpleLogin)} />
          <Route
            exact
            path="/dashboard"
            component={UserIsAuthenticated(Tracker(DashboardV3))}
          />
          <Route
            exact
            path="/dashboard/:page"
            component={UserIsAuthenticated(Tracker(DashboardV3))}
          />
          <Route
            exact
            path="/dashboard/v2"
            component={UserIsAuthenticated(Tracker(DashboardV2))}
          />
          <Route
            exact
            path="/reset/request"
            component={MemberDirect(RequestReset)}
          />
          <Route exact path="/account" component={AccountManagement} />
          <Route exact path="/plans" component={Tracker(PlansV3)} />
          <Route exact path="/plans/v2" component={Tracker(Plans)} />
          <Route
            exact
            path="/settings"
            component={UserIsAuthenticated(Tracker(Settings))}
          />
          <Route
            exact
            path="/settings/:page"
            component={UserIsAuthenticated(Tracker(Settings))}
          />
          <Route
            exact
            path="/settings/:page/:id"
            component={UserIsAuthenticated(Tracker(Settings))}
          />
          <Route
            exact
            path="/payment/:action"
            component={UserIsAuthenticated(Tracker(Payment))}
          />
          <Route
            exact
            path="/video/p"
            component={UserIsAuthenticated(Tracker(VideoPopout))}
          />
          <Route
            exact
            path="/doc/"
            component={UserIsAuthenticated(Tracker(EditDoc))}
          />
          <Route
            exact
            path="/doc/:id"
            component={UserIsAuthenticated(Tracker(EditDoc))}
          />
          <Route
            exact
            path="/transcript/:id"
            component={UserIsAuthenticated(Tracker(EditTranscript))}
          />
          <Route
            exact
            path="/transcript/:id/versions/:version"
            component={UserIsAuthenticated(Tracker(EditTranscript))}
          />
          <Route
            exact
            path="/edit-v2/:id"
            component={UserIsAuthenticated(Tracker(EditTranscript))}
          />
          <Route
            exact
            path="/public/:id/:pubkey"
            component={Tracker(EditTranscript)}
          />
          <Route
            exact
            path="/corrector"
            component={UserIsCorrector(Tracker(Corrector))}
          />
          <Route
            exact
            path="/corrector/guide"
            component={UserIsCorrector(
              Tracker((props) => <Corrector guide {...props} />)
            )}
          />
          <Route
            exact
            path="/corrector/admin/:adminPage"
            component={UserIsCorrectorAdmin(CorrectorAdmin)}
          />
          <Route
            exact
            path="/corrector/:locale/:type/:id"
            component={UserIsCorrector(Tracker(EditTranscript))}
          />
          <Route
            exact
            path="/corrector/:locale/:type/:id"
            component={UserIsCorrector(Tracker(EditTranscript))}
          />
          <Route
            exact
            path="/corrector/:locale/:type/:id/versions/:version"
            component={UserIsCorrector(Tracker(EditTranscript))}
          />
          <Route exact path="/not_allowed" component={NotAllowed} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/unsupported" component={Unsupported} />
          <Route exact path="/d" component={UserIsAuthenticated(Diagnostics)} />
          <Route exact path="/forced_error" component={ForcedErrorPage} />
          <Route exact path="/force_error" component={ForcedErrorPage} />
          <Route
            exact
            path="/sandbox"
            component={UserIsAuthenticated(Sandbox)}
          />
          <Route exact path={'/401'} component={Unauthorized} />
          <Route exact path={'/403'} component={Forbidden} />
          <Route exact path={'/404'} component={NotFound} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    );
  }
};
