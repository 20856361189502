import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { verifyEmail } from 'utils/Firebase';
import styled from 'styled-components';
import qs from 'query-string';
import KonchButton from 'components/common/Button';
import LogoImg from 'static/images/logo.png';
import Spinner from 'components/common/Spinner';
import { sendEmailVerification } from 'utils/Firebase';
import { toast } from 'components/common/Toaster';
import MailIcon from '@material-ui/icons/MailOutline';
import { Button } from '@material-ui/core';

const StyledButton = styled(KonchButton)`
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
`;

const Root = styled.div`
  height: 100vh;
  background: none;
  overflow-x: hidden;
`;

const Logo = styled.div`
  position: absolute;
  padding: 20px 0 0 20px;
  & img {
    width: 80px;
  }
  @media (max-width: 1000px) {
    & img {
      width: 49px;
    }
  }
`;

const Cont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 550px;
  & button {
    text-transform: initial;
    width: 100%;
    padding: 10px;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    & .left {
      max-height: 600px;
    }
    & .right {
      justify-content: end;
      max-height: 900px;
    }
  }
`;

const Panel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  &.right {
    background: #fff1e5;
    display: flex;
    align-items: left;
    flex-direction: column;
  }
`;

const H1 = styled.h1`
  margin-bottom: 35px;
  font-weight: 100;
  color: #444;
  text-align: center;
  @media (max-width: 700px) {
    font-size: 1.4em;
    padding: 0;
    margin: 19px;
  }
`;

const VerifyEmailTitle = styled.h1`
  margin-bottom: 5px;
  font-weight: 100;
  color: #444;
  text-align: center;
  @media (max-width: 700px) {
    font-size: 1.4em;
    padding: 0;
    margin: 19px;
  }
`;

const VerifyEmailSubtitle = styled.h3`
  font-weight: 100;
  color: #444;
  text-align: center;
  @media (max-width: 700px) {
    font-size: 1.3em;
    padding: 0;
    margin: 19px;
  }
`;

export const ResendContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 14px;
  .MuiButton-root {
    background-color: none;
    color: dodgerblue;
    text-transform: CAPITALIZE;
  }
`;

const Form = styled.form`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: column;
  width: 326px;
  text-align: left;
  p {
    text-align: center;
  }
  input {
    text-align: left;
    display: block;
    padding: 6px 0 3px 0;
    width: 100%;
    padding: 10px;
    margin-bottom: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .MuiButton-containedPrimary {
    background-color: #4fae32;
    margin-top: 10px;
  }
`;

const A = styled(Link)`
  color: #2f85bb;
  :hover {
    text-decoration: underline;
  }
`;

const Footer = styled.p`
  color: #777;
  padding: 10px;
  font-size: 0.9em;
`;

const EnterprisePanel = ({ settings }) => (
  <Panel className="right" style={settings}>
    <img
      src={settings.logo}
      alt="custom organization logo"
      style={{ maxHeight: '60%', maxWidth: '60%' }}
    />
  </Panel>
);

const DefaultPanel = () => (
  <Panel className="right">
    <div style={{ maxWidth: '650px' }}>
      <img
        style={{ maxWidth: '750px' }}
        src="https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/626854649ad4b4d88bc50419_send_email_market.svg"
        alt="classroom setting"
      />
    </div>
  </Panel>
);

export default (props) => {
  const { history, settings = {}, reminder } = props;
  const [processing, setProcessing] = useState(true);
  const [result, setResult] = useState(true);
  const [sending, setSending] = useState(0);

  const handleResend = async () => {
    if (sending > 0) return;
    setSending(1);
    try {
      await sendEmailVerification();
      setSending(2);
    } catch (error) {
      console.log('could not complete', error);
      toast.error(
        <div>
          {error?.code || ''}: {error?.message || ''}
        </div>,
        { autoClose: false }
      );
      setSending(3);
    }
  };

  const verifyCode = async (code) => {
    if (!code) history.push('/account?mode=verifyEmail');
    else {
      const { error, response } = await verifyEmail(code);
      console.log({ response });
      if (!error || result !== 'asdfdsdsf') {
        setResult(
          <span>
            Verified. <A to="/dashboard">Open dashboard.</A>
          </span>
        );
      } else {
        console.log({ error });
        setResult(
          <div style={{ textAlign: 'center' }}>
            <p>
              <b>Unable to verify your email.</b>
            </p>
            <small style={{ color: '#444' }}>{error.message}</small>
          </div>
        );
      }
    }
    setProcessing(false);
  };

  useEffect(() => {
    console.log(props.location.search);
    const { oobCode } = qs.parse(props.location.search.substr(1));
    if (oobCode) verifyCode(oobCode);
    else {
      setProcessing(false);
      setResult('Unable to verify your email.');
    }
    document.title = 'Verifying email';
    return () => {
      document.title = 'Konch';
    };
  }, []);

  // Reminder that user needs to verify email before they can use the product
  if (reminder) {
    return (
      <Root>
        {!settings.active && (
          <Logo>
            <img src={LogoImg} width="100px" alt="logo" />
          </Logo>
        )}
        <Cont>
          <Panel className="left">
            <Form noValidate autoComplete="off">
              <MailIcon
                style={{ marginRight: 10, color: '#777' }}
                fontSize="large"
              />
              <VerifyEmailTitle>
                Verify your email to continue.
              </VerifyEmailTitle>
              <VerifyEmailSubtitle>
                We sent you an email with a link to verify your email address.
              </VerifyEmailSubtitle>
              <Footer>
                Don't see the email?
                <ResendContainer>
                  <Button
                    onClick={handleResend}
                    disabled={sending > 0}
                    variant="container"
                  >
                    {sending === 3 ? (
                      'Error'
                    ) : sending === 1 ? (
                      'Sending'
                    ) : sending === 2 ? (
                      'Sent! Check Junk?'
                    ) : (
                      <span>Resend</span>
                    )}
                  </Button>
                </ResendContainer>
              </Footer>
            </Form>
          </Panel>
          <DefaultPanel />
        </Cont>
        <StyledButton raised primary onClick={() => history.push('/logout')}>
          Logout
        </StyledButton>
      </Root>
    );
  }

  return (
    <Root>
      {!settings.active && (
        <Logo>
          <img src={LogoImg} width="100px" alt="logo" />
        </Logo>
      )}
      <Cont>
        <Panel className="left">
          <Form noValidate autoComplete="off">
            <H1>Verifying your email</H1>
            {processing ? <Spinner /> : result}
            <Footer>
              Don't have an account, yet? <A to="/register">Create one here</A>
            </Footer>
          </Form>
        </Panel>
        {settings.active ? <EnterprisePanel {...props} /> : <DefaultPanel />}
      </Cont>
      {!settings.active && (
        <StyledButton raised primary onClick={() => history.push('/register')}>
          Register for Free
        </StyledButton>
      )}
    </Root>
  );
};
