import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import HappyIcon from '@material-ui/icons/SentimentVerySatisfied';
import SmileIcon from '@material-ui/icons/SentimentSatisfied';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Dialog,
  Divider,
  Fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  useMediaQuery
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckRounded';
import { useStyles } from './FeatureModal.styles';
import Spinner from '../Spinner';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const FeatureModal = ({ isOpen, onClose, featureDetails }) => {
  const history = useHistory();
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:600px)');

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsButtonDisabled(false);
    }
  }, [isOpen]);

  if (!featureDetails) {
    return null;
  }

  const handleButtonClick = () => {
    if (featureDetails.onButtonClick) {
      setIsButtonDisabled(true);
      featureDetails.onButtonClick();
      return;
    }

    history.push('/plans');
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{ className: clsx(classes.card) }}
      fullScreen={matches}
      style={{
        zIndex: 10_0000
      }}
    >
      <Fade in timeout={1000}>
        <Button
          disableRipple
          className={classes.preview}
          style={{ transitionDelay: '3s' }}
          onClick={onClose}
        >
          Close
        </Button>
      </Fade>
      <div className={classes.content}>
        <div className={classes.medal}>
          <HappyIcon style={{ color: '#555', fontSize: 49 }} className="one" />
          <SmileIcon style={{ color: '#555', fontSize: 49 }} className="two" />
        </div>
        <h2>{featureDetails.title}</h2>
        <p>{featureDetails.description}</p>
        {featureDetails?.highlightsHeader && (
          <div style={{ paddingTop: 16 }}>
            <h3>{featureDetails.highlightsHeader}</h3>
            <List dense>
              {featureDetails.highlights.map((item) => (
                <ListItem>
                  <ListItemIcon>
                    <div className={classes.check}>
                      <CheckIcon />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={item} />
                </ListItem>
              ))}
            </List>
          </div>
        )}
      </div>
      <Divider />
      <div
        className={classes.actions}
        style={{
          justifyContent:
            !featureDetails?.footerTitle && !featureDetails?.footerSubtitle
              ? 'center'
              : 'space-between'
        }}
      >
        <div className="footer">
          <p>
            {featureDetails.footerTitle}
            <br />
            <small style={{ color: '#AAA' }}>
              {featureDetails.footerSubtitle}
            </small>
          </p>
        </div>

        <Button
          onClick={handleButtonClick}
          variant="contained"
          className="buy-credits-button"
          disabled={isButtonDisabled}
          color="primary"
          disableElevation={true}
        >
          {featureDetails.buttonTitle}
          {isButtonDisabled && (
            <Spinner
              small
              style={{
                marginLeft: 10
              }}
            />
          )}
        </Button>
      </div>
    </Dialog>
  );
};
